export default class LazyLoader {
    static get xlsx() {
        return import(
        /* webpackChunkName: "export", webpackMode: "lazy" */ 'xlsx');
    }
    static get hljs() {
        return Promise.resolve(window.hljs ||
            import(
            /* webpackChunkName: "highlight", webpackMode: "lazy" */ '../third-party/highlight.js').then(result => result.default));
    }
    static table() {
        return import(
        /* webpackChunkName: "table", webpackMode: "lazy" */ 'dash-table/dash/fragments/DataTable');
    }
}
